import React, { useState, useEffect } from "react";
import ResourcesBg from "../../assets/FAQ/FAQ-Banner.png";
import "./Faq.css";

function Faq() {
  const [activeSection, setActiveSection] = useState(null);
  const [activeSubsection, setActiveSubsection] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? null : index);
    document.querySelectorAll('.faq-section-title')[index].classList.toggle('active');
  };
  
  const toggleSubsection = (sectionIndex, subsectionIndex) => {
    setActiveSubsection((prevState) => ({
      ...prevState,
      [sectionIndex]: prevState[sectionIndex] === subsectionIndex ? null : subsectionIndex,
    }));
    document.querySelectorAll('.faq-subsection-title')[subsectionIndex].classList.toggle('active');
  };
  

  const faqData = [
    {
      title: "How do I start?",
      subsections: [
        { subheading: "I am not a known shipper. Can I ship with ICL Transport & Supply Chain?", content: "In order to ship through ICL TSCM you must be a known shipper.  However, you can still avail services by contacting our customer service and sharing the KYC as will be requested.  You may opt to sign up with us and create an account.  Account will be valid for a period of 3 months of nil shipping from the date of the creation of the account or last shipment date." },
        { subheading: "What documents are required as part of KYC?", content: "If you are an individual a govt id proof, your contact coordinates which includes postal address along with proof, mobile number to receive your OTP. <br/> If you are an enterprise your company’s incorporation certificate, GST certificate, PAN, Bank Passbook."},
        { subheading: "Can I apply online to get my account number?", content: "Yes.  You can apply online to get your account number.  Once you submit your credentials online, our team will normally take 24 hours to verify and activate.  However, if you want to execute a shipment urgently our customer service will be readily available to help your shipment." },
        { subheading: "Can I use my account number to ship from multiple airports?", content: "You can.  However, you must make sure that are clear on the GST billing.  In case you need location-wise billing then you will have to create multiple accounts for each of the locations you would want to avail our services from" }
      ]
    },
    {
      title: "Basic Cargo Questions",
      subsections: [
        { subheading: "Where does ICL TSCM have nonstop service?", content: "ICL TSCM is connects you to multiple airlines and provides NON STOP flight schedules of multiple airlines when you submit your requirements.  In case you need to have the schedules of NON STOP service then please contact customer service." },
        { subheading: "Can ICL TSCM help in carrying Odd-size or over-weight cargo?", content: "Few airlines do operate wide-body aircraft for specific origin / destination pairs.  Please contact customer service to know the details of these cities." },
        { subheading: "Can I ship my excess baggage or other personal effects by quICkL?", content: "Yes you can ship your excess baggage subject to security check.  Ensure that you check for the items that can be shipped through excess baggage before it is tendered for shipping." },
        { subheading: "Are quICkL and freight service available to every destination American Airlines serves?", content: "We offer quICkL service to almost 30+ cities.  Our customer service will be quick to help you with your specific queries on the same." },
        { subheading: "What are the weight and height restrictions for the cargo I can ship?", content: "All cargo tendered have to be pass through security.  The standard size restricts are 90 x 90 x 90 cms.  However, they are subject to change based on the shipping locations...The standard weight restrictions are 68 kgs per piece.  You may ship any number of pieces and upliftment will be based on flight payload available and schedules...Please contact customer service for specific details." },
        { subheading: "Do your cargo locations provide refrigeration for perishable products?", content: "Yes we do provide at specific locations.  Please do contact our customer service for latest update.  The cities equipped for reefer services are also regularly updated on our site." },
        { subheading: "Is my shipment insured? If so, how much insurance is included?", content: "Our limits of liability may vary based on the type of service you are using.  Besides, it is preferred that you insure your cargo.  Our liability is limited to freight refund subject to a maximum of Rs.7500.00...Should you need assistance in insuring your shipment, our specialists will be more than happy to get your cargo insured." }
      ]
    },
    {
      title: "Booking & Payment",
      subsections: [
        { subheading: "What mode of payment do you accept?", content: "If you are an account holder you may make our payments directly to our bank account that is shared when your account is activated...We do accept credit cards, mobile payments as well." },
        { subheading: "Do you accept cash payments?", content: "Yes.  Please ensure to get your online cash receipt for the payments made by cash.  Else your shipment transportation may not happen." },
        { subheading: "If I don't have a credit account, can I pay for a shipment online?", content: "Yes.  You may make payments through mobile...." },
        { subheading: "Can I pay my invoice online?", content: "Yes, you can make your invoice payments to our bank account directly." },
        { subheading: "What is a letter of indemnity?", content: "A letter of indemnity is a statement in writing that the shipper will submit at the time of booking the shipment on charges collect basis.  Shipper will be responsible for payment of services in the event that the consignee fails to do so." }
      ]
    },
    {
      title: "Account and AWB",
      subsections: [
        { subheading: "Once I'm activated, am I active in all domestic stations?", content: "Upon signing the bilateral agreement, you are automatically activated for all domestic stations. Please allow 5 business days to process all activation requests..." },
        { subheading: "Can I create AWB at ICLTSCM.com?", content: "Yes your awb gets created once you confirm the booking and complete the payments.  You may print the same and share the document along with the shipment for transportation." }
      ]
    },
    {
      title: "Booking / Tendering",
      subsections: [
        { subheading: "Can I drop off my Parcel shipment at the cargo terminal?", content: "Yes, if your booking is Airport to Airport you will have to tender the cargo at our facility at the airport.  You may opt for door pickup at the time of booking or call our customer service for pickup service at additional cost." },
        { subheading: "What is the earliest or latest I can drop off cargo before a flight?", content: "Your Cargo Drop Time is mentioned in the booking slip.  Normally you will have to drop the cargo at our facility in airport 3 hours before the flight departure." },
        { subheading: "I was on time to my drop-off location, so why did I miss the cut off?", content: "Your cargo may have not been connected on account of insufficient paperwork or non-acceptance by the airline / flight.  You may contact our customer service for more details." },
        { subheading: "Do you provide packing supplies at the airport?", content: "Packing supplies are available at our facility at airport.  However, we appreciate if the requirement is informed in advance.  Packing material are standard and you will have to check if it meets your cargo dimensions and weight." }
      ]
    },
    {
      title: "Cold Chain / Packaging / DG",
      subsections: [
        { subheading: "Do you handle temperature-controlled cargo?", content: "The dedicated temperature-controlled spaces in our facilities are designed to protect ph" },
        { subheading: "What support do we get for temperature-controlled cargo?", content: "We offer cold chain packing solutions based on the customer needs.  These services are also complemented with reefer vehicles to ensure that the integrity of cold chain remains protected to the best extent possible. All cold chain shipments are tracked and enabled to provide visibility to customers." },
        { subheading: "Do you offer reefer container transportation services?", content: "We do provide reefer container transportation services that can meet the different temperature ranges desired by the customer." },
        { subheading: "Do you offer solutions for shipping DG cargo?", content: "Yes we do.  However there are restrictions.  Not all airlines carry DG cargo.  You will have to share some mandatory documents to our customer service who will assist you with a suitable solution." },
        { subheading: "What packing solutions do you provide?", content: "We provide general packing solutions – boxes of 10 kgs and 25kgs which may be used for shipping your general cargo...We do offer cold chain packing solutions for shipping your temperature sensitive shipment.  You will have to contact our customer service and provide details of the temperature range desired...We also have DG certified professionals who will assist you in finding solutions for shipping your DG cargo.  We do provide packing solutions for your DG shipments.  Kindly contact our customer service to know more about the packing solutions for your DG shipments." }
      ]
    },
    {
      title: "Claims",
      subsections: [
        { subheading: "How do I submit a claim?", content: "In case your shipment is lost or damaged, you can make a claim against them.  You will have to report the incident at the time of receipt of the shipment and call our customer service to register your claim...." },
        { subheading: "How do I request a proof of delivery?", content: "You can access our website with your awb no.  You will see the delivery details for your shipment if it is delivered.  The details are electronic.  However, if you want a signed / stamped acknowledgement from the cargo receiver please inform our customer service before delivery to organise for the same..." },
        { subheading: "What will I need to provide when submitting a claim?", content: [
          "We’re confident in our service, but in the event that your shipment gets lost or damaged, you can submit a claim at the link above. Make sure to have all of the relevant information handy to complete the form.",
          "AWB",
          "Original Invoice",
          "Inspection Report",
          "Photos of damaged pieces",
          "AWB or delivery receipt (from American Airlines)",
          "Repair bill or estimate",
          "Salvage value",
          "Current location of damaged goods",
          "Payment receipts"
        ] }
      ]
    }
  ];

  return (
    <div className="resources-container-page">
      <div className="faq-section-1" style={{ backgroundImage: `url(${ResourcesBg})` }}>
        <div className="faq-title">Frequently Asked Questions</div>
      </div>
      <div className="faq-title-second-heading">Packaging Solutions</div>
      <div className="faq-heading-sub">
        Explore our innovative packaging solutions tailored to meet your needs
      </div>
      <div className="resources-content-div">
        {faqData.map((section, sectionIndex) => (
          <div key={sectionIndex} className="faq-section">
            <div
              className="faq-section-title"
              onClick={() => toggleSection(sectionIndex)}
            >
              {section.title}
            </div>
            {activeSection === sectionIndex && (
              <div className="faq-subsection-container">
                {section.subsections.map((subsection, subsectionIndex) => (
                  <div key={subsectionIndex} className="faq-subsection">
                    <div
                      className="faq-subsection-title"
                      onClick={() => toggleSubsection(sectionIndex, subsectionIndex)}
                    >
                      {subsection.subheading}
                    </div>
                    {activeSubsection[sectionIndex] === subsectionIndex && (
                      <div className="faq-subsection-content">
                        {Array.isArray(subsection.content) ? (
                          <ul>
                            {subsection.content.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          <p dangerouslySetInnerHTML={{ __html: subsection.content }}></p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
