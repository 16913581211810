import React from "react";
import { useEffect } from "react";
import CareerBg from "../../assets/Services/Service-Banner.jpg";
import FirstImageService from "../../assets/Services/Quick.jpeg";
import SecondImageService from "../../assets/Services/Ace.jpeg";
import ThirdImageService from "../../assets/Services/Gem.jpg";
import FourthImageService from "../../assets/Services/Ground.png";
import FifthImageService from "../../assets/Services/Kelvin.png";
import CarIcon from "../../assets/Icons/noun-car-7060396.png";
import PharmaIcon from "../../assets/Icons/noun-medical-6867595.png";
import BusIcon from "../../assets/Icons/noun-bus-7060370.png";
import ClotheIcon from "../../assets/Icons/noun-cloths-2134268.png";
import EngineerIcon from "../../assets/Icons/noun-engineering-5799135.png";
import FishIcon from "../../assets/Icons/noun-fish-7097034.png";
import ItIcon from "../../assets/Icons/noun-it-5208982.png";
import PrinterIcon from "../../assets/Icons/noun-printer-5595503.png";
import TelephoneIcon from "../../assets/Icons/noun-telephone-5652599.png";
import DrugIcon from "../../assets/Icons/noun-giving-drug-6238813.png";
import "./Services.css";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="service-container">
      <div
        className="service-section-1"
        style={{ backgroundImage: `url(${CareerBg})` }}
      >
        <div className="services-title">Services</div>
      </div>
      <div className="service-content-div">
        <div className="service-title-second-heading">Our Services</div>
        <div className="service-heading-sub">
          Comprehensive Solutions for Your Transport and Logistics Needs
        </div>
        <div className="service-layout">
          <div className="service-left">
            <div className="service-item-home">
              <div className="service-icon">
                <img src={FirstImageService} alt="Service 1" />
              </div>
              <div className="service-title">ICL QUICK</div>
              <div className="service-info">
                <div className="service-info-inner">
                  <span className="service-info-up">ICL QUICK</span>
                  <div className="break-line"></div>
                  <span className="service-info-down">
                    If you are looking to expedite the delivery of your
                    shipment, look no further than our ICL Quick service. This
                    high-priority service will connect your shipment to the next
                    available flight from the origin to the destination. This
                    service offers doorstep pickup and delivery.
                  </span>
                  <button
                    className="service-redirect-button"
                    onClick={() => window.open("https://app.iclscm.com/", "_blank")}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
            <div className="service-item-home">
              <div className="service-icon">
                <img src={FourthImageService} alt="Service 4" />
              </div>
              <div className="service-title">ICL GROUND</div>
              <div className="service-info">
                <div className="service-info-inner">
                  <span className="service-info-up">ICL GROUND</span>
                  <div className="break-line"></div>
                  <span className="service-info-down">
                    If you are looking for a shipping service that is both
                    economical and flexible, then this is the one for you. This is
                    an economy service offering where both full truck and small
                    parcel shipping is done using road transport.
                  </span>
                  <button
                    className="service-redirect-button"
                    onClick={() => window.open("https://app.iclscm.com/", "_blank")}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="service-center">
          <div className="service-item-home">
              <div className="service-icon">
                <img src={FifthImageService} alt="Service 5" />
              </div>
              <div className="service-title">ICL KELVIN</div>
              <div className="service-info">
                <div className="service-info-inner">
                  <span className="service-info-up">ICL KELVIN</span>
                  <div className="break-line"></div>
                  <span className="service-info-down">
                    If you are shipping items that need to be transported in
                    temperature-controlled containers, then avail of this service.
                    This service provides cold chain transportation through
                    dedicated Reefer vehicles or premium packing solutions to carry
                    the shipments at controlled temperatures supported by continuous
                    monitoring.
                  </span>
                  <button
                    className="service-redirect-button"
                    onClick={() => window.open("https://app.iclscm.com/", "_blank")}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          
          </div>
          <div className="service-right">
        
            <div className="service-item-home">
              <div className="service-icon">
                <img src={SecondImageService} alt="Service 2" />
              </div>
              <div className="service-title">ICL ACE</div>
              <div className="service-info">
                <div className="service-info-inner">
                  <span className="service-info-up">ICL ACE</span>
                  <div className="break-line"></div>
                  <span className="service-info-down">
                    This is an express air cargo service that is highly time-bound,
                    offering reliable transportation of shipments by commercial
                    airlines. When you avail of ICL ACE service for your shipment,
                    you can rest assured that delivery will be done as per the
                    commitment given to you which is usually within 24 – 48 hours.
                  </span>
                  <button
                    className="service-redirect-button"
                    onClick={() => window.open("https://app.iclscm.com/", "_blank")}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          <div className="service-item-home">
              <div className="service-icon">
                <img src={ThirdImageService} alt="Service 3" />
              </div>
              <div className="service-title">ICL GEM</div>
              <div className="service-info">
                <div className="service-info-inner">
                  <span className="service-info-up">ICL GEM</span>
                  <div className="break-line"></div>
                  <span className="service-info-down">
                    Our ICL PRECIOUS/GEM service helps you transport your
                    valuable/precious cargo to specific destinations in the country.
                    Equipped with real-time tracking and tech-secured delivery you
                    can now safely transport your high-value items such as gems,
                    gold, silver and more.
                  </span>
                  <button
                    className="service-redirect-button"
                    onClick={() => window.open("https://app.iclscm.com/", "_blank")}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div className="upper-section-service">
        <div className="testimonials-title-service">Segments Served</div>
        <div className="testimonials-heading-service">
          Driving Innovation with Cutting-Edge Tools
        </div>
      </div>
      <div className="last-section-service">
        <div className="segments-deal-main">
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={DrugIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">PHARMACEUTICALS</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={BusIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">CONSOLIDATORS</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={ItIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">IT</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={PharmaIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">MEDICAL</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={TelephoneIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">TELECOM</div>
          </div>
        </div>

        <div className="segments-deal-main">
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={ClotheIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">GARMENTS</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={FishIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">PERISHABLES</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={PrinterIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">PRINTING & PACKAGING</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={EngineerIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">ENGINEERING</div>
          </div>
          <div className="segments-deal">
            <div className="icon-segment">
              <img src={CarIcon} alt="iconmedicine" />
            </div>
            <div className="name-segment">AUTOMOTIVES</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
