// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import PackagingSolutions from "./Pages/PackagingSolutions/PackagingSolutions";
import Faq from "./Pages/Faq/Faq";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar"
import Services from "./Pages/Services/Services";
import './styles/App.css'

function App() {
  return (
    <Router>
      <div>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/PackagingSolutions" element={<PackagingSolutions/>} />
          <Route path="/Faq" element={<Faq/>} />
          <Route path="/contact" element={<ContactUs/> } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
