import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import CompanyLogo from '../assets/Icons/PastedGraphic-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; 

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  

  return (
    <nav className="navbar">
      <div className="navbar-inner">
        <div className="navbar-left">
          <img src={CompanyLogo} alt="Company Logo" className="navbar-logo-home" />
        </div>
        <div className={`navbar-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <ul>
            <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
            <li><NavLink to="/about" activeClassName="active">About Us</NavLink></li>
            <li><NavLink to="/services" activeClassName="active">Services</NavLink></li>
            <li><NavLink to="/PackagingSolutions" activeClassName="active">Packaging Solutions</NavLink></li>
            <li><NavLink to="/Faq" activeClassName="active">FAQ</NavLink></li>
            <li><NavLink to="/contact" activeClassName="active">Contact Us</NavLink></li>
            <li>
              <a href="https://app.iclscm.com/" target="_blank" rel="noopener noreferrer">
                Track
              </a>
            </li>
            <li>
              <a href="https://app.iclscm.com/" target="_blank" rel="noopener noreferrer">
                Login
              </a>
            </li>
          </ul>
        </div>
        <div className="navbar-toggle" onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
