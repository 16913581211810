import React, { useEffect, useState } from "react";
import { createInquiry } from "../../API/user";
import IndustriesBg from "../../assets/PackageSolution/packaging-solutions-banner.jpg";
import ImageOne from "../../assets/PackageSolution/ColdChain.jpg";
import ImageTwo from "../../assets/PackageSolution/DG.jpg";
import ContactusIageform from "../../assets/Home/plane.jpg"
import Innovation from "../../assets/Idea.svg";
import Integrity from "../../assets/Technology.svg";
import Excellence from "../../assets/Excellence.svg";
import "./PackagingSolutions.css";

function PackagingSolutions() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    message: '',
    option: 'CC'
  });

  const [loading, setLoading] = useState(false); // Add loading state

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Full Name is required.";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.mobile_number.trim()) {
      newErrors.mobile_number = "Mobile Number is required.";
    } else if (!/^\d{10}$/.test(formData.mobile_number)) {
      newErrors.mobile_number = "Mobile Number must be 10 digits.";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    if (validateForm()) {
      try {
        const response = await createInquiry(formData);
        alert('Inquiry submitted successfully!');
        setFormData({
          name: '',
          email: '',
          mobile_number: '',
          message: '',
          option: 'DG'
        });
      } catch (error) {
        if (error.response && error.response.data.non_field_errors) {
          alert('An inquiry with the same email and mobile number has already been submitted.');
        } else {
          console.error('Error:', error);
          alert('An error occurred. Please try again.');
      setLoading(false); // Stop loading
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="industries-section-1" style={{ backgroundImage: `url(${IndustriesBg})` }}>
        <div className="industries-title">Packaging Solutions</div>
      </div>
      <div className="industries-section">
        <div className="industries-text-left">
          <h2>TEMPERATURE CONTROLLED PACKAGING SOLUTIONS</h2>
          <p>
            We provide you a highly reliable and cost effective temperature controlled packaging to ensure safe transportation of your temperature sensitive cargo. Our wide range of well-engineered and qualified packaging systems provide you customised solutions.
          </p>
        </div>
        <div className="industries-image-right">
          <img src={ImageOne} alt="Cold Chain" />
        </div>
      </div>

      <div className="industries-section reverse">
        <div className="industries-image-left">
          <img src={ImageTwo} alt="Dangerous Goods" />
        </div>
        <div className="industries-text-right">
          <h2>ICL OFFERS PACKAGING SOLUTIONS FOR DGR AND TEMPERATURE CONTROLLED PACKAGING SOLUTIONS</h2>
          <p>
            We at ICL provide you cost effective DGR packaging for your shipments which are classified as Dangerous Goods. Our qualified and experienced team will guide you through the suitable packaging solutions and ensure hassle free transportation solutions.
          </p>
        </div>
      </div>
      <div className="industries-success-container-core">
        <div className="aboutus-success-item-one">
          <div className="industries-success-count">
            {" "}
            <img src={Innovation} alt="Innovation-pic" />
          </div>
          <div className="industries-success-subheading">Safe & Secure</div>
          <div className="industries-success-text">
            You benefit from our experience in delivering effective solutions to the complex global supply chains of some of the world’s biggest corporations.
          </div>
        </div>
        <div className="aboutus-success-item-two">
          <div className="industries-success-count">
            <img src={Integrity} alt="Integrity-pic" />
          </div>
          <div className="industries-success-subheading">Fast Delivery</div>
          <div className="industries-success-text">
            You benefit from every innovation, whether it involves a simple extension to our air and ocean freight products, whether it means a development in warehousing.
          </div>
        </div>
        <div className="aboutus-success-item-one">
          <div className="industries-success-count">
            <img src={Excellence} alt="Excellence-pic" />
          </div>
          <div className="industries-success-subheading">24/7 Support</div>
          <div className="industries-success-text">
            All of which explains why you’ll find the team of outstanding support at ICL ready to apply their passion for solutions in support of your business.
          </div>
        </div>
      </div>

      <div className="industries-section">
        <div className="industries-image-left">
          <img src={ContactusIageform} alt="Contactus" />
        </div>
        <div className="industries-contact-form">
          <h2>Contact Us for Packaging Solutions</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
            <input
              type="text"
              name="mobile_number"
              placeholder="Mobile Number"
              value={formData.mobile_number}
              onChange={handleInputChange}
              required
            />
            {errors.mobile_number && <span className="error-message">{errors.mobile_number}</span>}
            <select
              name="option"
              value={formData.option}
              onChange={handleInputChange}
              required
            >
              <option value="DG">Dangerous Goods</option>
              <option value="CC">Cold Chain</option>
            </select>
            <textarea
              className="message-textarea-contactus"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
            {errors.message && <span className="error-message">{errors.message}</span>}
            <button type="submit" className="submit-btn-form-home" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PackagingSolutions;
