// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { createShipment } from '../API/user';
import '../styles/Home.css';
import SecondImage from "../assets/Home/plane.jpg";
import Client1 from "../assets/Home/AirAsia.png";
import Client2 from "../assets/Home/Akasa.png";
import Client3 from "../assets/Home/Airindia.png";
import Client4 from "../assets/Home/Vistara.jpg"
import Client5 from "../assets/Home/Indigo.jpg";

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [formData, setFormData] = useState({
    option: 'ICL Quick',
    city_of_departure: '',
    total_gross_weight: '',
    delivery_city: '',
    length: '',
    payment_status: 'Prepaid',
    breadth: '',
    email: '',
    height: '',
    mobile_number: '',
    message: ''
  });

  const images = [
    { src: Client1, quote: 'Adding wings and wheels to your cargo' },
    { src: Client2, quote: 'Connect to over 30 destinations overnight' },
    { src: Client3, quote: 'Explore your chance of sending your shipment by air at the cost of surface transport' },
    { src: Client4, quote: 'Enjoy an hassle free movement of your shipments by air' },
    { src: Client5, quote: 'Call us for cost options - we offer choice of transit times at varied costs' },
  ]

  document.addEventListener('scroll', () => {
    document.querySelectorAll('.card').forEach(card => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
            card.classList.add('visible');
        }
    });
});

const handleShipCargoClick = () => {
  window.open("https://app.iclscm.com/", "_blank");
};

const [loading, setLoading] = useState(false); // Add loading state

const handleSubmit = async (event) => {
  event.preventDefault();
  setLoading(true); // Start loading
  try {
    const response = await createShipment(formData);
    alert('Request Quote has been submitted successfully!');
    setFormData({
      option: '',
      city_of_departure: '',
      total_gross_weight: '',
      delivery_city: '',
      length: '',
      payment_status: '',
      breadth: '',
      email: '',
      height: '',
      mobile_number: '',
      message: ''
    });
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred. Please try again.');
    setLoading(false); // Stop loading
  }
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData(prevState => ({
    ...prevState,
    [name]: value
  }));
};


useEffect(() => {
  const interval = setInterval(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, 10000); 

  return () => clearInterval(interval);
}, [images.length]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='main-home-container'>
    <div className="home-container">
      <div className="main-div" style={{ backgroundImage: `url(${images[currentImageIndex].src})` }}>
        <div className="image-overlay">
          <div className="quote-text">{images[currentImageIndex].quote}</div>
          <div className="button-container">
            <button className="submit-btn-form-header" onClick={handleShipCargoClick}>
              SHIP YOUR CARGO NOW
            </button>
          </div>
        </div>
      </div>
    </div>
      <div className="about-us-container">
        <div className="about-us-left">
          <div className="image-wrapper">
            <img src={SecondImage} alt="Second" className="second-image" />
          </div>
        </div>
        <div className="about-us-right">
          <div className="about-us-content">
            <div className="text-section">
              <div className="who-are-we">WHO ARE WE</div>
              <div className="title">ICL TRANSPORT & SUPPLY CHAIN MANAGEMENT LLP</div>
              <div className="description">
                We are providers of integrated multi-modal transport services and supply chain solutions. Our express air and road solutions which includes reefer and traditional full truck operations have well embraced technology to help customers execute transportation seamlessly.
              </div>
              <div className="mission-section">
                <div className="mission">
                  Our highly customer-centric team blends well to understand the shipper and consignee requirements and ensures free-flow of communication for an outstanding service experience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-container" onSubmit={handleSubmit}>
  <h2 class="form-heading">Request a Free Quote</h2>
  <div class="form-wrapper">
    <form action="#" method="post" class="quote-form">
      <div class="request_quote">
        <div class="row">
          <div class="col">
            <div class="input-group">
            <select name="option" value={formData.option} onChange={handleInputChange}>
                      <option value="ICL Quick">ICL QUICK</option>
                      <option value="ICL Ace">ICL ACE</option>
                      <option value="ICL Kelvin">ICL KELVIN</option>
                      <option value="ICL Gem">ICL GEM</option>
                      <option value="ICL Ground">ICL GROUND</option>
                    </select>
            </div>
            <div class="input-group">
            <input
                      type="text"
                      name="total_gross_weight"
                      value={formData.total_gross_weight}
                      onChange={handleInputChange}
                      placeholder="Total gross weight (KG)"
                    />
            </div>
            <div class="input-group">
            <input
                      type="text"
                      name="length"
                      value={formData.length}
                      onChange={handleInputChange}
                      placeholder="Length (in cm)"
                    />
            </div>
            <div class="input-group">
            <input
                      type="text"
                      name="breadth"
                      value={formData.breadth}
                      onChange={handleInputChange}
                      placeholder="Breadth (in cm)"
                    />
            </div>
            <div class="input-group">
            <input
                      type="text"
                      name="height"
                      value={formData.height}
                      onChange={handleInputChange}
                      placeholder="Height (in cm)"
                    />
            </div>
          </div>
          <div class="col">
            <div class="input-group">
            <input
                      type="text"
                      name="city_of_departure"
                      value={formData.city_of_departure}
                      onChange={handleInputChange}
                      placeholder="City of departure"
                      required
                    />
            </div>
            <div class="input-group">
            <input
                      type="text"
                      name="delivery_city"
                      value={formData.delivery_city}
                      onChange={handleInputChange}
                      placeholder="Delivery city"
                    />
            </div>
            <div class="input-group">
            <select
                      name="payment_status"
                      value={formData.payment_status}
                      onChange={handleInputChange}
                    >
                      <option value="Prepaid">Prepaid</option>
                      <option value="To pay">To Pay</option>
                    </select>
            </div>
            <div class="input-group">
            <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      required
                    />
            </div>
            <div class="input-group">
            <input
                      type="tel"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleInputChange}
                      placeholder="Mobile number"
                      required
                    />
            </div>
          </div>
        </div>
      </div>
      <div class="input-group">
      <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Message"
                rows="4"
              ></textarea>
            </div>
            <div class="input-group">
              <button type="submit" className="submit-btn-form-home" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
            </div>
    </form>
  </div>
</div>
    </div>
  );
}

export default Home;
